import env from '@beam-australia/react-env'
import {
  initializeAndStartRecordingDatadogRum,
  initializeDatadogBrowserLogs,
} from '@knauf-group/ct-shared-nextjs/datadog'

const service = 'cms-frontend'

const datadogJsonConfig = JSON.parse(env('DATADOG_CONFIG') || '{}')

if (env('DATADOG_IS_RUM_ENABLED') === 'true') {
  initializeAndStartRecordingDatadogRum({
    service,
    version: `${env('APP_CMS_VERSION')}`,
    sessionSampleRate: datadogJsonConfig.sessionSampleRate,
    sessionReplaySampleRate: datadogJsonConfig.sessionReplaySampleRate,
    trackUserInteractions: datadogJsonConfig.trackUserInteractions,
    trackResources: datadogJsonConfig.trackResources,
    trackLongTasks: datadogJsonConfig.trackLongTasks,
    defaultPrivacyLevel: datadogJsonConfig.defaultPrivacyLevel,
    silentMultipleInit: datadogJsonConfig.silentMultipleInit,
    beforeSend: (_, context) => !('isAborted' in context && context.isAborted),
  })
}

if (env('DATADOG_ARE_BROWSER_LOGS_ENABLED')) {
  initializeDatadogBrowserLogs({
    service,
    version: `${env('APP_CMS_VERSION')}`,
    // Use before send to filter out aborted requests logs to datadog
    beforeSend: (_, context) => !('isAborted' in context && context.isAborted),
  })
}
