import type { ComponentType } from 'react'
import dynamic from 'next/dynamic'

const fontMapping: Record<string, () => Promise<{ default: ComponentType<{}> }>> = {
  Arabic: () => import('@components/KnaufFont/ArabicFont'),
  Hebrew: () => import('@components/KnaufFont/HebrewFont'),
  Japanese: () => import('@components/KnaufFont/JapaneseFont'),
  Korean: () => import('@components/KnaufFont/KoreanFont'),
  Thai: () => import('@components/KnaufFont/ThaiFont'),
  defaultNotoSans: () => import('@components/KnaufFont/defaultAlternative'),
  knauf: () => import('@components/KnaufFont/KnaufFont'),
}

export const getFontVariant = (fontToUse: string): ComponentType<{}> => {
  const loadFontComponent =
    fontMapping[fontToUse] || (() => import('@components/KnaufFont/defaultAlternative'))

  return dynamic(loadFontComponent)
}
