import type { ImageLoader } from 'next/image'
import type { NextRouter } from 'next/router'

import { default as i18nConfig } from '@config/i18n'
import createCache from '@emotion/cache'
import { DEFAULT_RENDITION_IMAGE_QUALITY } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import type { ImageWeb } from '@knauf-group/ct-designs/utils/types'
import { isValidUrl } from '@knauf-group/ct-designs/utils/utils'
import type { Entry, EntrySkeletonType } from '@knauf-group/ct-shared-nextjs/web/contentful'
import type { SiteStructure } from '@knauf-group/ct-shared-nextjs/web/siteStructure'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { Logger } from '@lib/logger'
import type { Theme } from '@mui/material/styles'
import { prefixer } from 'stylis'
import stylisRTLPlugin from 'stylis-plugin-rtl'

import {
  DEFAULT_DEVICE_PIXEL_RATIO,
  PRODUCT_IMAGE_BACKGROUND_COLOR,
  SMART_CROP_TOKENS,
  TAG_PREFIXES,
} from './constants'
import type { ImageProps, TagPrefixKeyProp } from './types'

export const sanitizeString = (str: string) =>
  str && str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '')

export const addDash = (str: string) => str && str.replace(/ /g, '-')

export const toKebabCase = (str: string) => {
  const sanitizedString = sanitizeString(str)
  return addDash(sanitizedString)
}

export const capitalize = (str: string): string => {
  if (!str) {
    return null
  }
  return str[0].toUpperCase() + str.slice(1)
}

export const formatLocale = (locale: string) => {
  if (!locale) {
    return null
  }
  return locale
    .toLowerCase()
    .split('-')
    .map((str) => capitalize(str))
    .join('')
}

export const contentfulImageToOpenGraph = (image: {
  url: string
  width: number
  height: number
  description?: string
}) => {
  if (!image) {
    return undefined
  }

  return {
    url: image.url,
    width: image.width,
    height: image.height,
    ...(image.description && { alt: image.description }),
  }
}

/**
 * Returns a href locale suffix for the provided locale and returns empty string for default locale
 * @param locale
 * @returns locale including prepended slash
 **/
export const getHrefLocaleSuffix = (locale: string): string => {
  return i18nConfig?.defaultLocale !== locale ? `/${locale}` : ''
}

/**
 * Returns an url without double slashes recommended to pass without the BASE_URL
 * @param url without BASE_URL so https is not included on the replace
 * @returns an url without double slashes
 **/
export const replaceDoubleSlash = (url: string): string => {
  return url.replace(/\/\//g, '/')
}

/**
 * Convert all required types of an interface to optional, except for the specified key(s)
 * Usage:
 * PartialBy<type, 'required_key'>
 * PartialBy<type, 'required_key1' | ... |'required_keyN'>
 */
export type PartialBy<T, K extends keyof T> = Partial<T> & Pick<T, K>

/**
 * Remove key(s) from an interface
 * Usage:
 * ExcludeOnly<type, 'key_to_exclude'>
 * ExcludeOnly<type, 'key_to_exclude1' | ... |'key_to_excludeN'>
 */
export type ExcludeOnly<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

/**
 * Compares the route for the passed page id to the current route
 * @param pageId - the page id to compare to the current route
 * @param siteStructure - the site structure object
 * @param router - the next router object
 * @returns true if route for the passed pageId matches the current route, false if it doesn't
 */
export const isPageActive = (
  pageId: string,
  siteStructure: SiteStructure,
  router: NextRouter,
): boolean => {
  try {
    return pageId && pageId === siteStructure?.getPage(router?.asPath)?.pageId
  } catch (error) {
    Logger.error(`Error checking if page is active: ${error}`)
    return false
  }
}

/**
 * Setting a priority on which device pixel ratio should be used helps avoiding unnecessary rerenders(more than 1 download for the same image) on client side
 * @param devicePixelRatio devicePixelRatio coming from device or identified by the browser(window.devicePixelRatio)
 * @returns by default the constant DEFAULT_DEVICE_PIXEL_RATIO unless devicePixelRatio from device is higher
 */
export const getDevicePixelRatioPriority = (devicePixelRatio: number) =>
  devicePixelRatio > DEFAULT_DEVICE_PIXEL_RATIO ? devicePixelRatio : DEFAULT_DEVICE_PIXEL_RATIO

export const getSmartCropUrlGenerator = (
  smartCropTokenName: string,
  src: string,
  devicePixelRatio: number,
) => {
  const url = new URL(src)
  const baseHref = `${url.origin}${url.pathname}`

  return `${baseHref}:${smartCropTokenName}?dpr=on,${devicePixelRatio}&fmt=webp`
}

// Default smartcrop image loader
export const getSmartCropImageLoaderByTokenName =
  (smartCropTokenName: string, devicePixelRatio: number): ImageLoader =>
  ({ src }) => {
    if (!isValidUrl(src)) {
      Logger.error(`Invalid url(${src}) used for smart crop image loader by token name.`)
      return null
    }
    return getSmartCropUrlGenerator(smartCropTokenName, src, devicePixelRatio)
  }

// Background/edge case image loader
export const getSmartCropBackgroundImageLoader =
  (
    contentType: string,
    breakpointsValues: Theme['breakpoints']['values'],
    devicePixelRatio: number | null,
  ): ImageLoader =>
  ({ src, width }) => {
    if (!isValidUrl(src)) {
      Logger.error(`Invalid url(${src}) used for ${contentType} image loader.`)
      return null
    }

    const devicePixelRatioAdjustedWidth = Math.round(width / devicePixelRatio)

    if (contentType === CONTENTFUL_TYPES.CONTENT_STAGE) {
      if (devicePixelRatioAdjustedWidth > breakpointsValues.sm) {
        return getSmartCropUrlGenerator(
          SMART_CROP_TOKENS.CF_HERO_TEASER_BG_M,
          src,
          devicePixelRatio,
        )
      }
      return getSmartCropUrlGenerator(
        SMART_CROP_TOKENS.CF_HERO_TEASER_BG_XS,
        src,
        devicePixelRatio,
      )
    }

    if (
      contentType === CONTENTFUL_TYPES.CONTENT_CTA_REPEATER ||
      contentType === CONTENTFUL_TYPES.CONTENT_SMALL_HERO_TEASER
    ) {
      if (devicePixelRatioAdjustedWidth > breakpointsValues.md) {
        return getSmartCropUrlGenerator(
          SMART_CROP_TOKENS.CF_BACKGROUND_L,
          src,
          devicePixelRatio,
        )
      }
      return getSmartCropUrlGenerator(SMART_CROP_TOKENS.CF_BACKGROUND_S, src, devicePixelRatio)
    }

    Logger.error(`Background smart crop loader definition not available for ${contentType}.`)
    return null
  }

// Product image loader
export const getRenditionImageLoader = (devicePixelRatio: number): ImageLoader => {
  return ({ src, width, quality = DEFAULT_RENDITION_IMAGE_QUALITY }) => {
    return `${src}?dpr=on,${devicePixelRatio}&wid=${width}&hei=${width}&qlt=${quality}&fmt=webp&bgc=${PRODUCT_IMAGE_BACKGROUND_COLOR}`
  }
}

// baseline loader, use when other loader scenarios do not apply
export const getDefaultImageLoader = (): ImageLoader => {
  return ({ src, width, quality = DEFAULT_RENDITION_IMAGE_QUALITY }) => {
    return `${src}?wid=${width}&qlt=${quality}&fmt=webp`
  }
}

export const getImageWithFallback = (
  image: ImageProps | null,
  fallbackUrl: string,
): ImageProps => {
  if (!image?.url) {
    // it should always return a fallback url to handle missing for mandatory cases or broken image scenarios
    return {
      url: undefined,
      title: '',
      fallbackUrl,
    }
  }

  const { url, title } = image

  return {
    ...image,
    url,
    fallbackUrl,
    title: title || '',
  }
}

export const getImagesWithFallback = (images: ImageProps[] | null, fallbackUrl: string) => {
  return images?.map((image) => {
    return getImageWithFallback(image, fallbackUrl)
  }) as ImageWeb[]
}

// TODO: move these helper functions to ct-shared once it's stable

/**
 * @param metadata contentful content entry metadata (this is where CF tags are made available)
 * @param tagPrefixKey CF tag prefix key (e.g. 'FT') - relevant because we have different tag prefixes for namespacing different tag types
 * @returns matching tags (e.g. ['FT_1', 'FT_2', 'FT_3']) or empty array if no matches
 */

export const getTagsMatchingPrefix = (
  metadata: Entry<EntrySkeletonType>['metadata'],
  tagPrefixKey: TagPrefixKeyProp,
): string[] => {
  if (!metadata?.tags?.length || !tagPrefixKey) {
    return []
  }

  return metadata.tags
    .filter(({ sys: { id: tagId } }) => tagId.startsWith(TAG_PREFIXES[tagPrefixKey]))
    .map(({ sys: { id: tagId } }) => tagId.split('_')[1])
}

/**
 * @param contentEntry contentful content entry
 * @param tagPrefixKey CF tag prefix key (e.g. 'feature') - relevant because we have different tag prefixes for namespacing different tag types
 * @param matchOptions array of tag values to match against (e.g. ['showCookieListTable', 'enableSomeOtherFeature'])
 * @returns true if there is a match, false if there is no match
 */
export const hasMatchWithTags = (
  contentEntry: ContentEntry<EntrySkeletonType>,
  tagPrefixKey: TagPrefixKeyProp,
  matchOptions: string[] = [],
): boolean => {
  const tags = getTagsMatchingPrefix(contentEntry.metadata, tagPrefixKey)

  if (tags.length > 0) {
    return tags.some((tagId) =>
      matchOptions.map((match) => match.toLowerCase()).includes(tagId.toLowerCase()),
    )
  }

  return false
}

const rtlCache = createCache({
  key: 'rtlcache',
  stylisPlugins: [prefixer, stylisRTLPlugin],
})
const ltrCache = createCache({ key: 'ltrcache' })

export const getEmotionCache = (isRTL: boolean) => (isRTL ? rtlCache : ltrCache)

export const getAlphabetFromIndex = (index: number) => String.fromCharCode(65 + index)

export const groupBy = <T>(
  ungroupedArray: T[],
  predicate: (value: T, index: number, array: T[]) => string,
): Record<string, T[]> =>
  ungroupedArray.reduce((acc, value, index, array) => {
    const key = predicate(value, index, array)
    acc[key] = acc[key] || []
    acc[key].push(value)
    return acc
  }, {} as { [key: string]: T[] })
