import { createContext, useContext, useMemo, useState } from 'react'
import Script from 'next/script'

import type { HubSpotProviderProps } from './HubSpotForm.types'

export const HubSpotContext = createContext({
  isScriptLoaded: false,
  isScriptError: false,
})

export const useHubSpotContext = () => useContext(HubSpotContext)

const HubSpotProvider = ({ children }: HubSpotProviderProps) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [isScriptError, setIsScriptError] = useState(false)
  const memoizedProviderVals = useMemo(
    () => ({ isScriptLoaded, isScriptError }),
    [isScriptLoaded, isScriptError],
  )

  return (
    <>
      <Script
        src="https://js.hsforms.net/forms/v2.js"
        crossOrigin="anonymous"
        onReady={() => {
          setIsScriptLoaded(true)
        }}
        onError={() => {
          setIsScriptError(true)
        }}
      />
      <HubSpotContext.Provider value={memoizedProviderVals}>{children}</HubSpotContext.Provider>
    </>
  )
}

export default HubSpotProvider
