import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'
import dynamic from 'next/dynamic'

const ErrorVisual = dynamic(() =>
  import('@cms/components/ErrorVisualWrapper').then((mod) => mod.default),
)

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorVisual />
    }

    return this.props.children
  }
}

export default ErrorBoundary
