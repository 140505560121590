/* eslint-disable @typescript-eslint/no-var-requires */
const env = require('@beam-australia/react-env')
const i18nDev = require('./i18n.dev.json')
const i18nPreProd = require('./i18n.preprod.json')
const i18nProd = require('./i18n.prod.json')
const i18nStage = require('./i18n.stage.json')
const i18nTraining = require('./i18n.training.json')

const i18nConfig = {
  dev: i18nDev,
  preprod: i18nPreProd,
  prod: i18nProd,
  stage: i18nStage,
  training: i18nTraining,
}

module.exports = {
  ...i18nConfig[env('APP_ENV') || 'prod'],
}
